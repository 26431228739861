import React from 'react'
import { cn } from '~/utils/cn'
import * as styles from './IconButton.styles'

export type IconButtonProps<T extends IntrinsicElement = 'button'> = {
  component?: T
} & React.ComponentPropsWithoutRef<T> &
  styles.Variants

const IconButton = <T extends IntrinsicElement = 'button'>({
  className,
  color,
  size,
  component = 'button' as T,
  ...restProps
}: IconButtonProps<T>) => {
  return React.createElement(component, {
    type: component === 'button' ? 'button' : undefined,
    className: cn(styles.iconButtonVariants({ color, size }), className),
    ...restProps
  })
}

export default IconButton
